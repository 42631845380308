import {
  SignupTemplateType,
  SurveyQuestionType,
  SurveyTemplate as SurveyTemplateType,
} from "app-types/template";
import GlobalWrapper from "components/core/GlobalWrapper";
import React, { useState } from "react";
import SEO from "components/core/SEO";
import { useTranslation } from "react-i18next";
import { SURVEY_TEMPLATES_PATH } from "constants/paths";
import MessengerPreview from "components/ui/extended/MessengerPreview";
import {
  DescriptionCol,
  Dot,
  DotsContainer,
  QuestionsContainer,
  TemplateContainer,
} from "templates/templates/survey/index.style";
import ButtonQuestion from "components/ui/extended/MessengerPreview/Survey/Question/ButtonQuestion";
import CommunityAuthorBox from "components/ui/extended/CommunityAuthorBox";
import { HeaderContainer, PageContainer } from "styles/global.style";
import { Row } from "styled-bootstrap-grid";
import { TemplateBackground } from "templates/templates/canned-response/index.style";
import Fade from "components/core/Animation/extended/Fade";
import Heading from "components/core/Typography/Heading";
import Breadcrumbs from "components/core/Breadcrumbs";
import SafeLink from "components/core/SafeLink";
import Button from "components/core/Button";
import { CTAColor } from "app-types/color";
import NumberSteps from "components/ui/base/NumberSteps";
import StarQuestion from "components/ui/extended/MessengerPreview/Survey/Question/StarQuestion";
import TextboxQuestion from "components/ui/extended/MessengerPreview/Survey/Question/TextboxQuestion";
import TextareaQuestion from "components/ui/extended/MessengerPreview/Survey/Question/TextareaQuestion";
import TestimonialWithImageDaniele from "components/ui/extended/TestimonialWithImageDaniele";
import VideoPreview from "components/ui/base/VideoPreview";
import RegisterForCustomerSatisfaction from "components/ui/extended/RegisterForCustomerSatisfaction";
import Customers from "components/ui/extended/Customers";
import { getTemplateSignupUrl } from "utils/path";
import { theme } from "styles/theme";
import BlogPostsBox from "components/ui/base/BlogPostsBox";

export interface SurveyTemplateProps {
  pageContext: {
    template: SurveyTemplateType;
  };
  location: Location;
}

const SurveyTemplate = ({ pageContext, location }: SurveyTemplateProps) => {
  const { t } = useTranslation();
  const [activeQuestion, setActiveQuestion] = useState<number>(0);
  const [paused, setPaused] = useState<boolean>(false);

  setTimeout(() => {
    if (!paused) {
      const nextActiveQuestion =
        (activeQuestion + 1) % pageContext.template.questions.length;
      setActiveQuestion(nextActiveQuestion !== 0 ? nextActiveQuestion : 0);
    }
  }, 5000);

  return (
    <GlobalWrapper
      withLayout
      layoutSettings={{ withCta: false }}
      location={location}
    >
      <SEO
        title={pageContext.template.name}
        description={pageContext.template.description}
        image={{
          relativePath: "meta/community-templates-for-saas.jpg",
          alt: pageContext.template.name,
        }}
        withoutTitleSuffix
      />

      <HeaderContainer>
        <PageContainer>
          <Row>
            <TemplateContainer lg={7} order={1} lgOrder={1}>
              <QuestionsContainer>
                {pageContext.template.questions.map((question, index) => {
                  switch (question.type) {
                    case SurveyQuestionType.Button:
                      return (
                        <TemplateBackground
                          show={activeQuestion === index}
                          key={`survey-question-${index}`}
                        >
                          <MessengerPreview
                            withBubble
                            direction={"left"}
                            accentColor={theme.accentColor}
                          >
                            <ButtonQuestion
                              title={question.title}
                              choices={question.choices}
                              accentColor={theme.accentColor}
                            />
                          </MessengerPreview>
                        </TemplateBackground>
                      );
                    case SurveyQuestionType.Textarea:
                      return (
                        <TemplateBackground
                          show={activeQuestion === index}
                          key={`survey-question-${index}`}
                        >
                          <MessengerPreview
                            withBubble
                            direction={"left"}
                            accentColor={theme.accentColor}
                          >
                            <TextareaQuestion
                              title={question.title}
                              description={question.subtitle}
                              accentColor={theme.accentColor}
                            />
                          </MessengerPreview>
                        </TemplateBackground>
                      );
                    case SurveyQuestionType.Textbox:
                    case SurveyQuestionType.Integer:
                      return (
                        <TemplateBackground
                          show={activeQuestion === index}
                          key={`survey-question-${index}`}
                        >
                          <MessengerPreview
                            withBubble
                            direction={"left"}
                            accentColor={theme.accentColor}
                          >
                            <TextboxQuestion
                              title={question.title}
                              description={question.subtitle}
                              accentColor={theme.accentColor}
                            />
                          </MessengerPreview>
                        </TemplateBackground>
                      );
                    case SurveyQuestionType.Star:
                      return (
                        <TemplateBackground
                          show={activeQuestion === index}
                          key={`survey-question-${index}`}
                        >
                          <MessengerPreview
                            withBubble
                            direction={"left"}
                            accentColor={theme.accentColor}
                          >
                            <StarQuestion
                              title={question.title}
                              description={question.subtitle}
                              accentColor={theme.accentColor}
                            />
                          </MessengerPreview>
                        </TemplateBackground>
                      );
                  }
                })}
                <DotsContainer>
                  {pageContext.template.questions.length > 1 &&
                    pageContext.template.questions.map((question, index) => {
                      return (
                        <Dot
                          onClick={() => {
                            setPaused(true);
                            setActiveQuestion(index);
                          }}
                          active={activeQuestion === index}
                          key={`survey-question-${index}`}
                        />
                      );
                    })}
                </DotsContainer>
              </QuestionsContainer>
            </TemplateContainer>
            <DescriptionCol lg={5} order={2} lgOrder={2}>
              <Fade direction={"up"} delay={400}>
                <Breadcrumbs
                  elements={[
                    {
                      name: "Customerly",
                      path: "/",
                    },
                    {
                      name: "Templates",
                      path: "/templates/",
                    },
                    {
                      name: "Surveys Templates",
                      path: `${SURVEY_TEMPLATES_PATH}/`,
                    },
                  ]}
                />
              </Fade>

              <Fade direction={"up"} delay={400}>
                <Heading level={1}>{pageContext.template.name}</Heading>
              </Fade>

              <Fade direction={"up"} delay={500}>
                <p>{pageContext.template.description}</p>
              </Fade>

              <Fade direction={"up"} delay={1000}>
                <Button
                  as={SafeLink}
                  to={getTemplateSignupUrl(
                    SignupTemplateType.Survey,
                    pageContext.template.survey_template_id
                  )}
                  icon={{ type: "chevron-right" }}
                  ctaColor={CTAColor.Yellow}
                >
                  {t("templates.templates.survey.useTemplate")}
                </Button>
              </Fade>
              <Fade direction={"up"} delay={1200}>
                <CommunityAuthorBox author={pageContext.template.author} />
              </Fade>
            </DescriptionCol>
          </Row>
          <NumberSteps
            title={t("templates.templates.survey.steps.title")}
            elements={[1, 2, 3].map((index) => ({
              title: t(
                `templates.templates.survey.steps.elements.${index}.title`
              ),
              description: t(
                `templates.templates.survey.steps.elements.${index}.description`
              ),
            }))}
          />
        </PageContainer>
      </HeaderContainer>

      <TestimonialWithImageDaniele />
      <VideoPreview
        id={"survey-responses"}
        title={t("templates.templates.survey.3.title")}
        paragraph={t("templates.templates.survey.3.subtitle")}
        storiesPosition={"right"}
        stories={[
          {
            relativePath: "templates/templates/survey/surveys-responses.mp4",
          },
        ]}
        cta={{
          text: t("templates.templates.survey.3.cta"),
          link: "/marketing-funnel/",
        }}
      />

      <RegisterForCustomerSatisfaction />

      <Customers />

      <BlogPostsBox
        category={"surveys"}
        title={"Surveys Articles"}
        maxPosts={3}
        postsPerRow={3}
        currentPostSlug={"/"}
      />
    </GlobalWrapper>
  );
};
export default SurveyTemplate;
